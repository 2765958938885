import Vue from 'vue';
import App from './App.vue';
import Toast from 'vue-toastification';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';

import store from './store';
import router from './router';
import i18n from './config/_i18n';
import './config/_vee_validate';
import './config/_vue_directives';
import globalMixin from './config/global.mixin';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toastification/dist/index.css';

Vue.mixin(globalMixin);

Vue.use(VueTheMask);

const vueCurrencyInputOptions = { globalOptions: { precision: 2, currency: false } };
Vue.use(VueCurrencyInput, vueCurrencyInputOptions);

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true
});

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

Vue.filter('toFixed', function(number, scale) {
  return new Number(number).toFixed(scale);
});

Vue.prototype.$recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;


new Vue({
  store,
  router,
  i18n,
  watch: {
    $route: () => {}
  },
  mounted() {},
  render: h => h(App)
}).$mount('#app');
