import i18n from './_i18n';

function accDiv(arg1, arg2) {
    var t1 = 0, t2 = 0, r1, r2;
    try { t1 = arg1.toString().split(".")[1].length } catch (e) { console.error(e) }
    try { t2 = arg2.toString().split(".")[1].length } catch (e) { console.error(e) }
    // with (Math) {
    //     r1 = Number(arg1.toString().replace(".", ""))
    //     r2 = Number(arg2.toString().replace(".", ""))
    //     return (r1 / r2) * pow(10, t2 - t1);
    // }
    r1 = Number(arg1.toString().replace(".", ""))
    r2 = Number(arg2.toString().replace(".", ""))
    return (r1 / r2) * Math.pow(10, t2 - t1);
}

Number.prototype.div = function (arg) {
    return accDiv(this, arg);
}
// String.prototype.div = function (arg) {
//     return accDiv(this, arg);
// }

function accMul(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) { console.error(e) }
    try { m += s2.split(".")[1].length } catch (e) { console.error(e) }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

Number.prototype.mul = function (arg) {
    return accMul(arg, this);
}
// String.prototype.mul = function (arg) {
//     return accMul(arg, this);
// }

const getProgressValue = (val) => {
    switch (val) {
      case 10:
        return 0.2;
      case 20:
        return 0.4;
      case 30:
        return 1;
      case 40:
        return 1;
      case 60:
        return 0.6;
      case 70:
        return 0.8;
      default:
        return 0;
    }
}
const getProgressColor = (val) => {
    switch (val) {
      case 10:
        return '#ff8448';
      case 20:
        return '#4792e3';
      case 30:
        return '#40b91b';
      case 40:
        return '#dd1919';
      case 60:
        return '#f9f911';
      case 70:
        return '#a211f9';
      default:
        return '#f97f11';
    }
}

const sortArrByString = (first, second) => {
  let fa = first.toLowerCase(), fb = second.toLowerCase();
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
}

const getMarginLevelStuffs = (level) => {
  const parsedValue = `${level}` === '0' ? 0 : level ? parseFloat(level) : 1;
  let labelAndClass = {
    label: i18n.t('marginTrade.moderate_risk'),
    class: 'text-warning',
    value: 1
  };
  if(parsedValue >= 0 && parsedValue < 1) {
      labelAndClass = {
        label: i18n.t('marginTrade.high_risk'),
        class: 'text-danger',
        value: 0.5
      };
  }
  if(parsedValue >= 1 && parsedValue <= 499) {
      labelAndClass = {
        label: i18n.t('marginTrade.moderate_risk'),
        class: 'text-warning',
        value: 1.5
      };
  }
  if(parsedValue > 499) {
      labelAndClass = {
        label: i18n.t('marginTrade.low_risk'),
        class: 'text-success',
        value: 2.5
      };
  }
  return labelAndClass;
}

const normalizeSearchQueryParam = (q, label) => {
  return q?.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(' ')
    .every((v) => label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(v));
}

const mod97 = (string) => {
  let checksum = string.slice(0, 2), fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

const isValidIBANNumber = (input) => {
  const CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 22, CT: 28, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
      AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };
  let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) === 1;
}

export default {
    accDiv,
    accMul,
    getProgressValue,
    getProgressColor,
    sortArrByString,
    getMarginLevelStuffs,
    normalizeSearchQueryParam,
    isValidIBANNumber
}