/* eslint-disable no-unused-vars */

// 12 Jan 2024 8:43 pm

// UserTypes
// 20 kurumsal
// 80 nft
// 10 bireysel
// 30 bayi

// Kurumsal(20) Müşteri Coin Alımı: %1
// Kurumsal(20) Müşteri Coin Satımı: %1
// NFT Müşterisi(80) Coin Alımı: %1
// NFT Müşterisi(80) Coin Satımı: %1
// Bireysel Müşteri(10) Coin Alımı: %2
// Bireysel Müşteri(10) Coin Satımı: %3
// Bayi Müşterisi(30) Coin Alımı: %2
// Bayi Müşterisi(30) Coin Satımı: %3

export const getWithdrawalFee = (userType = 10) => {
    /* switch (userType) {
        case 20:
        case 80:
            return 0.01;
        case 10:
        case 30:
            return 0.02;
        default:
            return 0.01;
    } */

    // return to 1% - 12 Jan 2024 10:17 pm
    return 0.01;
}
