<template>
  <div class="main-menu menu-fixed menu-dark menu-bg-default rounded menu-accordion menu-shadow">
    <div class="main-menu-content mainMenuContent" style="overflow-y: auto">
      <a href="https://miraclecash.com" target="_blank" class="navigation-brand d-none d-md-block d-lg-block d-xl-block">
        <img class="brand-logo" alt="Miracle Cash&More" :src="logoWhite" />
      </a>
      <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <!--
        <li class="nav-item">
          <router-link to="/"
            ><i class="icon-grid"></i
            ><span class="menu-title" data-i18n="">Dashboard</span></router-link
          >
        </li>
        -->
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/instant-trade">
            <i class="icon-fire"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-buyNow') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/wallet">
            <i class="icon-wallet"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-wallet') }}</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="hideRestrictedRouteInProd('/markets')">
          <router-link @click.native="toggleMenu" to="/markets">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:svgjs="http://svgjs.com/svgjs" xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="29"><svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 26 24"><g fill="#642583" class="color000 svgShape" data-name="Group 87" transform="translate(-4413 -2253)"><rect width="26" height="2" fill="#fff" class="coloraaa svgShape" data-name="Rectangle 99" rx="1" transform="translate(4413 2275)"/><g fill="none" stroke="#fff" stroke-width="2.5" class="colorStrokeaaa svgStroke" data-name="Rectangle 100" transform="translate(4413 2264)"><path stroke="none" d="M2,0H5A2,2,0,0,1,7,2V13a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"/><path fill="#642583" d="M2,1.25H5A.75.75,0,0,1,5.75,2v9.75a0,0,0,0,1,0,0H1.25a0,0,0,0,1,0,0V2A.75.75,0,0,1,2,1.25Z" class="color000 svgShape"/></g><g fill="none" stroke="#fff" stroke-width="2.5" class="colorStrokeaaa svgStroke" data-name="Rectangle 101" transform="translate(4422.5 2253)"><path stroke="none" d="M2,0H5A2,2,0,0,1,7,2V24a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"/><path fill="#642583" d="M2,1.25H5A.75.75,0,0,1,5.75,2V22.75a0,0,0,0,1,0,0H1.25a0,0,0,0,1,0,0V2A.75.75,0,0,1,2,1.25Z" class="color000 svgShape"/></g><g fill="none" stroke="#fff" stroke-width="2.5" class="colorStrokeaaa svgStroke" data-name="Rectangle 102" transform="translate(4432 2260)"><path stroke="none" d="M2,0H5A2,2,0,0,1,7,2V17a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"/><path fill="#642583" d="M2,1.25H5A.75.75,0,0,1,5.75,2V15.75a0,0,0,0,1,0,0H1.25a0,0,0,0,1,0,0V2A.75.75,0,0,1,2,1.25Z" class="color000 svgShape"/></g></g></svg></svg>
            </div>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-markets') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/deposit">
            <i class="icon-diamond"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-deposit') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/withdrawal">
            <i class="icon-arrow-down"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-withdrawal') }}</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="hideRestrictedRouteInProd('/deal')">
          <router-link @click.native="toggleMenu" to="/deal">
            <i class="icon-tag"></i>
            <span class="menu-title" data-i18n="">MIRACLE DEAL</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="hideRestrictedRouteInProd('/spot-trade')">
          <router-link @click.native="toggleMenu" to="/spot-trade">
            <div>
              <svg enable-background="new 0 0 32 32" height="31" viewBox="0 0 32 32" width="31" xmlns="http://www.w3.org/2000/svg"><g id="Layer_1"><g fill="#fff"><path d="m16 30c7.72 0 14-6.28 14-14s-6.28-14-14-14-14 6.28-14 14 6.28 14 14 14zm0-26c6.62 0 12 5.38 12 12s-5.38 12-12 12-12-5.38-12-12 5.38-12 12-12z"/><path d="m10 15h12c.5527 0 1-.4478 1-1s-.4473-1-1-1h-10.1313l.9634-1.4453c.3062-.4595.1821-1.0806-.2773-1.3867-.4604-.3071-1.0811-.1826-1.3867.2773l-2 3c-.2046.3066-.2236.7012-.0498 1.0264.1742.3252.513.5283.8817.5283z"/><path d="m10 19h10.1318l-.9639 1.4453c-.3066.4595-.1826 1.0806.2773 1.3867.171.1138.3634.168.5538.168.3232 0 .6406-.1563.833-.4453l2-3c.2041-.3066.2236-.7012.0498-1.0264s-.5127-.5283-.8818-.5283h-12c-.5522 0-1 .4478-1 1s.4478 1 1 1z"/></g></g></svg>
            </div>
            <span class="menu-title" data-i18n="">{{ $t('spot-trade') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/transaction-history">
            <div>
              <TransactionHistorySVG />
            </div>
            <span class="menu-title" data-i18n="">{{ $t('header-profile-history') }}</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="hideRestrictedRouteInProd('/margin-trade')">
          <router-link @click.native="toggleMenu" to="/margin-trade">
            <i class="icon-shuffle"></i>
            <span class="menu-title" data-i18n="">Margin Trade</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-if="hideRestrictedRouteInProd('/campaigns')">
          <router-link @click.native="toggleMenu" to="/campaigns">
            <i class="icon-envelope"></i>
            <span class="menu-title" data-i18n="">{{ $t('campaigns') }}</span>
          </router-link>
        </li> -->
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/personal/account-settings">
            <i class="icon-user-following"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-account') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import restrictedRoutesInProd from '../../config/restrictedProdRoutes';
import TransactionHistorySVG from '../icons/TransactionHistorySVG.vue';

export default {
  name: 'LeftMenu',
  components: {TransactionHistorySVG},
  methods: {
    toggleMenu() {
      if (window.innerWidth <= 768) {
        const body = document.body;
        body.classList.remove("menu-open", "menu-expanded");
        body.classList.add("menu-hide");
        //window.$.app.menu.toggle();
      }
    },
    hideRestrictedRouteInProd(path) {
      const isRestricted = restrictedRoutesInProd.some(val => path.indexOf(val) !== -1);
      const isProd = !this.isDev;
      // (isProd && isRestricted) hide: false, show: true
      return (isProd && isRestricted) ? false : true;
    },
    removeOpenClass() {
      document.querySelectorAll('#main-menu-navigation > li').forEach(item => {
        if(item.classList.contains('open')) {
          item.classList.remove('open');
        }
      })
    }

  },
  created() {
    this.toggleMenu();
    this.removeOpenClass();
  },
  watch: {
    '$route': 'removeOpenClass'
  }
};
</script>

<style lang="scss">
@media only screen and (max-width: 768px) {
  .vertical-overlay-menu .main-menu .navigation > li > a > i {
    float: none;
    font-size: 2.2rem;
  }
  .vertical-compact-menu .main-menu .navigation > li > a > span {
    visibility: visible;
    width: 100%;
  }
  body.menu-hide .main-menu {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mainMenuContent::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.mainMenuContent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
<style lang="scss" scoped>
.nav-item {
  a {
    &.router-link-exact-active, &.router-link-active {
      color: #FFFFFF;
      background: #522b67;
      border-right: 4px solid #321a3f;
    }
    &:hover {
      background: #522b67;
      opacity: 0.8;
    }
  }
}
</style>
