import iban from 'iban';
import i18n from './_i18n';
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import utils from './utils';

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty']
  }
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: (_, values) => i18n.t(`validation.${rule}`, values)
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: i18n.t('veeValidateExtraMsgs.passwords_do_not_match')
});
extend('strongPassword', {
  validate(value) {
    const strongRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$');
    return strongRegex.test(value);
  },
  message: i18n.t('veeValidateExtraMsgs.strong_password')
});
extend('img_required', {
  validate(imgUrl) {
    return imgUrl !== '';
  },
  message() {
    return i18n.t('veeValidateExtraMsgs.required_field');
  }
});
extend('file_size_less_than_mb', {
  params: ['size'],
  validate(files, args) {
    if(files && files[0] && files[0] instanceof File && args?.size) {
      const fileSizeInMb = Math.floor(files[0].size/(1024*1024));
      if(fileSizeInMb > Number(args.size)) {
        return false;
      }
    }
    return true;
  },
  message() {
    return i18n.t('veeValidateExtraMsgs.file_size_less_than_mb', { size: '15' });
  }
});

extend('max_amount', {
  params: ['maxAmount'],
  validate(value, { maxAmount }) {
    return parseFloat(value) <= parseFloat(maxAmount);
  },
  message (_, {maxAmount}) {
    return i18n.t('veeValidateExtraMsgs.max_amount', { amount: maxAmount });
  }
});

extend('iban_valid', {
  validate(value) {
    return iban.isValid(value)
  },
  message(){
    return i18n.t('veeValidateExtraMsgs.invalid_iban')
  }
});

extend('custom_iban_valid', {
  validate(value) {
    return utils.isValidIBANNumber(value);
  },
  message(){
    return i18n.t('veeValidateExtraMsgs.invalid_iban')
  }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
