<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('markets.title')"/>
    <Marquee />
    <div class="m-1 mt-2">
      <AccordionWidgets />
      <TableQuotes />
      <div class="row mt-3">
        <div class="col-12">
        <coingecko-coin-heatmap-widget style="width:100%;height:550px;" class="qwe" locale="en"></coingecko-coin-heatmap-widget>
        </div>
      </div>
      <Geomap />
      <Chart />
      <HeatMap />
      <TechnicalAnalysis />
      <Comparison />
      <div class="row m-1 mt-3 d-flex align-items-center">
          <div class="col-md-6 col-sm-12 my-2 pl-0">
            <coingecko-coin-converter-widget height="500" coin-id="bitcoin" currency="usd" background-color="#ffffff" font-color="#4c4c4c" locale="en"></coingecko-coin-converter-widget>        
          </div>
          <div class="col-md-6 col-sm-12 my-2 pr-0">
            <coingecko-coin-list-widget  coin-ids="bitcoin,ethereum,eos,ripple,litecoin" currency="usd" locale="en"></coingecko-coin-list-widget>
          </div>
      </div> 
    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb';
import TechnicalAnalysis from '@/components/markets/TechnicalAnalysis.vue';
import HeatMap from '@/components/markets/HeatMap.vue';
import Chart from '@/components/markets/Chart.vue';
import Geomap from '@/components/markets/Geomap.vue';
import TableQuotes from '@/components/markets/TableQuotes.vue';
import Marquee from '@/components/markets/Marquee.vue';
import AccordionWidgets from '@/components/markets/AccordionWidgets.vue';
import Comparison from '@/components/markets/Comparison.vue';

export default {
  name: 'Markets',
  components: {
    Breadcrumb,
    TechnicalAnalysis,
    HeatMap,
    Geomap,
    Marquee,
    Chart,
    AccordionWidgets,
    TableQuotes,
    Comparison
  },
  methods: {
    createScript(url) {
      let script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    },
    createLink(url) {
        let head  = document.getElementsByTagName('head')[0];
        let link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        head.appendChild(link);
    },
  },
  created() {
    const body = document.body;
    body.classList.add("aside-minimize");

    this.createLink('../widgets/assets/css/style.css');
    this.createScript('../widgets/assets/js/dist/app.js');
    this.createScript('https://widgets.coingecko.com/coingecko-coin-price-chart-widget.js');
    this.createScript('https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js');
    this.createScript('https://widgets.coingecko.com/coingecko-coin-heatmap-widget.js');
    this.createScript('https://widgets.coingecko.com/coingecko-coin-converter-widget.js');
    this.createScript('https://widgets.coingecko.com/coingecko-coin-list-widget.js');
  },
};
</script>
<style scoped>
#chart {
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 100px);
  float: left;
  margin: auto;
}
</style>