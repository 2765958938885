import API from '@/api';
import Services from '@/config/_axios';
import Vue from 'vue';
import { getErrorServiceMsg, getSuccessServiceMsg } from '@/config/serviceMessages';
import i18n from '@/config/_i18n';
import {OrderDirection, OrderType} from '@/enums/spot-trade-order.enum';
import router from '../../router';

const calculateTotal = (price, quantity) => {
  const priceToCalculate = price ? parseFloat(`${price}`.replace(/,/g, '')) :  0;
  const quantityToCalculate = quantity ? parseFloat(`${quantity}`.replace(/,/g, '')) : 0;
  return priceToCalculate * quantityToCalculate;
};
const spottrade = {
  state: () => ({
    allPairs: [],
    selectedPair: {
      baseCurrency: null,
      quoteCurrency: null,
      name: null
    },
    openOrders: [],
    completedOrders: [],
    pairBalance: null,
    selectedType: OrderType.Limit,
    selectedOrderDirection: OrderDirection.Buy,
    symbolTickerStreams: []
  }),
  mutations: {
    SET_SELECTED_TYPE(state, payload) {
      state.selectedType = payload;
    },
    SET_SELECTED_ORDER_DIRECTION(state, payload) {
      state.selectedOrderDirection = payload;
    },
    SET_SYMBOL_TICKER_STREAMS(state, payload) {
      state.symbolTickerStreams = payload;
    }
  },
  actions: {
    async SET_PAIR_PRICES({ state }, { pairsData, pairPrices }) {
      pairsData.forEach(async (pair) => {
        const name = pair.name.split('/');
        const baseCurrency = name[0];
        const quoteCurrency = name[1];
        // === 'USD' ? 'USDT' : name[1];
        const symbol = `${baseCurrency}${quoteCurrency}`;
        // params.push(`"${symbol}"`);
        pair.symbol = symbol;
        pair.baseCurrency = baseCurrency;
        pair.quoteCurrency = quoteCurrency;

        const foundPrice = pairPrices.find(pairPrice => pairPrice.symbol === pair.name);

        if (foundPrice) {
          pair.price = parseFloat(foundPrice.price);
        }

        state.allPairs.push(pair);
      });
    },
    async FETCH_CURRENCY_BALANCE(_, pair) {
      const param = `${pair.quoteCurrency}?base_currency=${pair.baseCurrency}`;
      const balance = await Services.get(`${API.currencyBalance}${param}`);
      return balance.data.data;
    },
    async SELECT_PAIR({ state }, pair) {
      state.selectedPair = pair;
      const selectedPairBalance = await this.dispatch('spottrade/FETCH_CURRENCY_BALANCE', pair);
      state.pairBalance = selectedPairBalance;

      /**
       * @INFO | Binance WS used before
       */
      // await this.dispatch('binance/INIT_TICKER_STREAMS', pair.symbol.toLowerCase());

      this.dispatch('spottrade/FETCH_TICKER_STREAMS', pair.name);

      await this.dispatch('binance/FETCH_PAIR_SYMBOL_TICKER_STREAMS', pair.symbol.toLowerCase());
      this.dispatch('spottrade/FETCH_ORDER_HISTORY');
    },
    async FETCH_ALL_PAIRS({ state }) {
      const fetchedPairs = await Services.get(API.spotTrade.allPairs);
      state.allPairs = [];
      const pairsData = fetchedPairs.data['all-pairs'];
      const pairPrices = fetchedPairs.data.pairs;

      await this.dispatch('spottrade/SET_PAIR_PRICES', { pairsData, pairPrices });
      this.dispatch('spottrade/SELECT_PAIR', state.allPairs[0]);
    },
    async FETCH_ORDER_HISTORY({ state }) {
      let url = API.spotTrade.orderHistory
      if(state.selectedPair?.name) {
        url = `${API.spotTrade.orderHistory}?pair=${state.selectedPair?.name}`;
      }
      const orderHistory = await Services.get(url);
      const fetchedOpenOrders = orderHistory.data.open_orders;

      fetchedOpenOrders.forEach(openOrder => {
        const isCancelled = openOrder.canceled_time > 0;
        openOrder.status = isCancelled ? 2 : openOrder.status;
      });

      state.openOrders = fetchedOpenOrders;
      state.completedOrders = orderHistory.data.completed_orders;
    },
    async SUBMIT_ORDER(_, order) {
      try {
        const resp = await Services.post(API.spotTrade.createOrder, order)
        this.dispatch('spottrade/FETCH_ORDER_HISTORY');
        this.dispatch('spottrade/SET_PAIR_BALANCE_BY_DIRECTION');
        Vue.$toast.success(getSuccessServiceMsg(resp));
        return 'success'
      } catch (err) {
        Vue.$toast.error(getErrorServiceMsg(err.response));
        return err;
      }
    },
    async CANCEL_ORDERS(_, orders) {
      const cancellingOrders = Array.isArray(orders) ? orders : [orders];
      cancellingOrders.forEach(async order => {
        const cancelEndpoint = `${API.spotTrade.cancelOrder}/${order.order_id}`;
        await Services.post(cancelEndpoint);
      });
      setTimeout(() => {
        this.dispatch('spottrade/FETCH_ORDER_HISTORY');
        Vue.$toast.success(i18n.t('service.operation_success'));
      }, 500);
    },
    async SET_PAIR_BALANCE_BY_DIRECTION({state}){
      try {
        let pair = { quoteCurrency: state.selectedPair.quoteCurrency, baseCurrency: state.selectedPair.baseCurrency};
        if(state.selectedOrderDirection === OrderDirection.Sell) {
          pair = { quoteCurrency: state.selectedPair.baseCurrency, baseCurrency: state.selectedPair.quoteCurrency};
        }
        const selectedPairBalance = await this.dispatch('spottrade/FETCH_CURRENCY_BALANCE', pair);
        state.pairBalance = selectedPairBalance;
      } catch (error) {
        console.log(error);
      }
    },
    async FETCH_TICKER_STREAMS({ commit }, symbol) {
      commit('SET_SYMBOL_TICKER_STREAMS', []);
      try {
        const res = await Services.get(API.spotTrade.symbolPlate(symbol));
        const { ask, bid } = res.data?.data;
        const buys = bid?.map((val) => ({ ...val, direction: 'BUY' })) || [];
        const sells = ask?.map((val) => ({ ...val, direction: 'SELL' })) || [];
        const _items =
          [...buys, ...sells]?.map((val) => ({
            lastPrice: val.price,
            lastQuantity: val.amount,
            lastAmount: calculateTotal(val.price, val.amount),
            direction: val.direction
          })) || [];
        commit('SET_SYMBOL_TICKER_STREAMS', _items);
        return res;
      } catch (error) {
        console.log('FETCH_TICKER_STREAMS@error ', error.response);
        return error;
      }
    }
  },
  getters: {
    allPairs: (state) => {
      if(router.currentRoute.name === 'MarginTrade') {
        return state.allPairs.filter(val => val.margin_status);
      }
      return state.allPairs.filter(val => val.spot_status);
    },
    selectedPair: (state) => state.selectedPair,
    pairBalance: (state) => state.pairBalance,
    openOrders: (state) => state.openOrders,
    completedOrders: (state) => state.completedOrders,
    selectedType: (state) => state.selectedType,
    selectedOrderDirection: (state) => state.selectedOrderDirection,
    symbolTickerStreams: (state) => state.symbolTickerStreams
  },
  namespaced: true
};

export default spottrade;
