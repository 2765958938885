import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/assets/languages/en.json';
import tr from '@/assets/languages/tr.json';
import de from '@/assets/languages/de.json';
import et from '@/assets/languages/et.json';
import fr from '@/assets/languages/fr.json';
import me from '@/assets/languages/me.json';

import { localeChanged } from 'vee-validate';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';
import MessagesME from 'vee-validate/dist/locale/en.json';

Vue.use(VueI18n);

const qs = new URLSearchParams(window.location.search);

if (qs.has('lang')) {
  sessionStorage.setItem('lang', qs.get('lang'));
}

let lang = sessionStorage.getItem('lang');
if (!lang) {
  /* const detectLang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
  if (detectLang === 'tr' || detectLang === 'tr-TR') {
    lang = 'tr';
  } else if (detectLang === 'nl' || detectLang === 'nl-NL') {
    lang = 'nl';
  } else if (detectLang === 'me' || detectLang === 'me-ME') {
    lang = 'me';
  } else if (detectLang === 'et' || detectLang === 'et-ET') {
    lang = 'et';
  } else if (detectLang === 'de' || detectLang === 'de-DE') {
    lang = 'de';
  } else {
    lang = 'en';
  } */
  lang = 'en';
  sessionStorage.setItem('lang', lang);
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: {
    en: { ...en, validation: MessagesEN.messages },
    tr: { ...tr, validation: MessagesTR.messages },
    de: { ...de, validation: MessagesDE.messages },
    et: { ...et, validation: MessagesET.messages },
    fr: { ...fr, validation: MessagesFR.messages },
    me: { ...me, validation: MessagesME.messages },
  }
});
document.documentElement.setAttribute('lang', lang);

export function changeLanguages(lang, refresh) {
  sessionStorage.setItem('lang', lang);
  i18n.locale = lang;
  localeChanged();
  if (refresh) {
    window.history.pushState({}, document.title, window.location.pathname);
    location.reload();
  }
}

export default i18n;
