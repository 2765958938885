<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form method="post" @submit.prevent="handleSubmit(handleContinue)">
      <div v-if="!showBankList">
        <div class="form-group">
          <div class="d-flex w-100 overflow-auto">
            <button
              type="button"
              style="width: 25%"
              class="btn btn-sm bg-lighten-1 font-weight-bold"
              :class="!isFiat ? 'bg-primary text-white' : 'text-secondary'"
              @click="onChangeFiatCrypto('crypto')"
            >
              Crypto
            </button>
            <button
              type="button"
              style="width: 25%"
              class="btn btn-sm bg-lighten-1 ml-2 font-weight-bold"
              :class="isFiat ? 'bg-primary text-white' : 'text-secondary'"
              @click="onChangeFiatCrypto('fiat')"
            >
              Fiat
            </button>
          </div>
        </div>
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('withdrawal-type') }}</label>
          <validation-provider rules="required" v-slot="{ classes, errors }">
            <div class="input-group">
              <multiselect
                v-model="selectedCurrency"
                @input="getCoinSelection"
                deselect-label=""
                select-label=""
                :class="classes"
                selected-label=""
                track-by="name"
                :label="$t('withdrawal-type')"
                :custom-label="currencySelectCustomLabel"
                :show-labels="false"
                :placeholder="$t('withdrawal-dropdownCryptoWallet')"
                :options="withdrawCurrency"
                :allow-empty="false"
                open-direction="bottom"
              >
                <!-- <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template> -->
                <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
                <template slot="noResult">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div v-if="selectedCurrency">
          <div class="form-group" v-if="selectedCurrency.code === 'USDT'">
            <label class="mr-sm-2">{{ $t('network') }}</label>
            <validation-provider rules="required" v-slot="{ classes, errors }">
              <div class="input-group">
                <multiselect
                  v-model="selectedNetwork"
                  @input="onNetworkChange"
                  deselect-label=""
                  select-label=""
                  :class="classes"
                  selected-label=""
                  track-by="name"
                  :label="$t('network')"
                  :options="networks"
                  :allow-empty="false"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    >{{ option.name }}
                  </template>
                  <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                  <template slot="noResult">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="mr-sm-2">{{ selectedCurrency.crypto ? $t('withdrawal-labelCrypto') : `${$t('bankSettings-account')} / IBAN` }}</label>
              <div>
                <span class="add-wallet" @click="onToggleShowBankList">
                  <template v-if="selectedCurrency.crypto">{{ $t('withdrawal-addCryptoWalletModal') }}</template>
                  <template v-else>{{ $t('withdrawal-addAccountModal') }}</template>
                </span>
              </div>
            </div>

            <validation-provider
              :rules="selectedCurrency.crypto ? 'required' : 'required|custom_iban_valid'"
              v-slot="{ classes, errors }"
              :name="selectedCurrency.crypto ? $t('withdrawal-addCryptoWalletAddress') : 'IBAN'"
            >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control provider-input"
                  :class="classes"
                  v-model="walletAddressIban"
                  :placeholder="selectedCurrency.crypto ? $t('withdrawal-addCryptoWalletAddress') : 'IBAN'"
                  @input="onChangeWalletAddressInput"
                />
                <multiselect
                  v-model="selectedProvider"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  track-by="code"
                  :label="$t('withdrawal-bank')"
                  :show-labels="false"
                  :placeholder="$t('withdrawal-dropdownCryptoWallet')"
                  :options="wallet"
                  :class="classes"
                  open-direction="bottom"
                  @input="onChangeProvider"
                >
                  <!-- <template slot="singleLabel" slot-scope="{ option }">{{ `${option.label} ${option.address}` }}</template> -->
                  <template slot="option" slot-scope="{ option }">{{ `${option.label} ${option.address}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                  <template slot="noResult">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="form-group" v-if="isFiat">
            <label class="mr-sm-2">{{ $t('withdrawal-bank') }}</label>
            <validation-provider
              v-if="!selectedProvider"
              rules="required"
              v-slot="{ classes, errors }"
              :name="$t('withdrawal-bank')"
            >
              <div class="input-group">
                <multiselect
                  v-model="selectedCommonBank"
                  deselect-label=""
                  select-label=""
                  :class="classes"
                  selected-label=""
                  track-by="name"
                  label="name"
                  :options="commonBanks"
                  :allow-empty="false"
                  :searchable="true"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.short}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.short}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <span class="form-control d-flex align-items-center" v-else>
              {{ selectedProvider.bank }}
            </span>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-6" v-if="selectedCurrency.crypto">{{ $t('withdrawal-labelAmountCrypto') }}</label>
              <label class="col-6" v-else>{{ $t('withdrawal-labelAmount') }}</label>
            </div>
            <div class="row"></div>
            <div class="row">
              <validation-provider
                :rules="'required|max_value:' + withdrawalBalanceNotFormatted"
                :name="selectedCurrency.crypto ? $t('withdrawal-labelAmountCrypto') : $t('withdrawal-labelAmount')"
                v-slot="{ classes, errors }"
                class="col-6"
              >
                <currency-input
                  id="currency_amount"
                  class="form-control"
                  autocomplete="off"
                  :distractionFree="false"
                  :class="classes"
                  v-model="amount"
                  :precision="selectedCurrency.crypto ? 8 : 2"
                  :placeholder="
                    selectedCurrency.crypto ? $t('withdrawal-labelAmountCrypto') : $t('withdrawal-labelAmount')
                  "
                />
                <span class="error__message">{{ errors[0] }}</span>
              </validation-provider>
              <div class="col-6">
                <div class="available-limit">
                  <p class="mb-0 font-small-2">{{ $t('withdrawal-availablelimit') }}</p>
                  <h6 class="mb-0 font-medium-5">{{ withdrawalBalance }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center mt-2">
            <button type="submit" class="btn-gradient-primary btn-block">{{ $t('withdrawal-btnContinue') }}</button>
          </div>
        </div>
      </div>
      <div v-else>
        <BankList :showWallets="selectedCurrency.crypto" />
        <div class="col-12 text-center mt-2 row">
          <div class="col-6">
            <button
              type="button"
              class="btn btn-outline-secondary -border mr-md-2 w-100"
              @click="onToggleShowBankList"
            >
              {{ $t('withdrawal-back') }}
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn-gradient-primary mt-1 mt-md-0 w-100 text-truncate"
              @click="addWalletBtn"
            >
              {{ selectedCurrency.crypto ? $t('withdrawal-addCryptoWalletModal') : $t('withdrawal-addAccountModal') }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <modal v-if="isTransfer" ref="confirmationModal" @onCloseModal="cancelTransfer">
      <template v-slot:header>
        <h1>{{ $t('withdrawal-btnConfirm') }}</h1>
      </template>
      <template v-slot:body>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-1">
          <span>{{ selectedCurrency.crypto ? $t('withdrawal-addCryptoWalletAddress') : 'IBAN' }}</span>
          <span class="font-weight-bold">
            {{ walletAddressIban }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-1">
          <span>{{ $t('withdrawal-labelAmount') }} ({{ selectedCurrency.code }})</span>
          <span class="font-weight-bold">
            {{ amount }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-1">
          <span>{{ $t('withdrawal-labelCommission') }} ({{ selectedCurrency.code }})</span>
          <span class="font-weight-bold">
            {{ transfer.fee ? transfer.fee : '' }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-2">
          <span>{{ $t('withdrawal-labelTotalAmount') }} ({{ selectedCurrency.code }})</span>
          <span class="font-weight-bold">
            {{ transfer.totalAmount ? transfer.totalAmount : '' }}
          </span>
        </div>
        <div class="row">
          <div class="col-6 text-center mt-2">
            <a type="button" class="btn btn-outline-secondary -border w-100" @click="cancelTransfer">
              {{ $t('withdrawal-btnCancel') }}
            </a>
          </div>
          <div class="col-6 text-center mt-2">
            <button type="button" class="btn-gradient-primary w-100" @click="createWithdrawal">
              {{ $t('commons.approve') }}
            </button>
          </div>
        </div>
      </template>
    </modal>
    <modal v-if="showCryptoServiceWarningModal" ref="cryptoServiceWarningModal" @onCloseModal="showCryptoServiceWarningModal = false">
      <template v-slot:header>
        <h1>{{ $t("commons.important_info") }}</h1>
      </template>
      <template v-slot:body>
        <div class="font-large-1">{{ $t('withdraw_crypto_service_warning') }}</div>
      </template>
    </modal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import BankList from '@/components/withdrawal/BankList';
import Modal from '@/components/global/Modal';
import {getWithdrawalFee} from '@/config/withdrawCommissions';

export default {
  name: 'WithdrawalForm',
  data() {
    return {
      showBankList: false,
      selectedFiatCrypto: 'crypto',
      selectedProvider: null,
      walletAddressIban: '',
      selectedCommonBank: null,
      showCryptoServiceWarningModal: true
    }
  },
  computed: {
    ...mapState({
      wallet: (state) => state.withdrawal.wallet,
      withdrawalBalance: (state) => state.withdrawal.withdrawalBalance,
      withdrawalBalanceNotFormatted: (state) => state.withdrawal.withdrawalBalanceNotFormatted,
      showAddBankAccount: (state) => state.withdrawal.showAddBankAccount,
      transfer: (state) => state.withdrawal.transfer,
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.customer.user,
      networks: (state) => state.withdrawal.networks,
      commonBanks: (state) => state.withdrawal.banks,
      isTransfer: (state) => state.withdrawal.isTransfer,
    }),
    ...mapGetters('customer', ['getUserType']),
    amount: {
      get() {
        return this.$store.state.withdrawal.amount;
      },
      set(value) {
        this.$store.commit('withdrawal/updateAmount', value);
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.withdrawal.selectedCurrency;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedCurrency', value);
      }
    },
    isFiat() {
      return this.selectedFiatCrypto === 'fiat';
    },
    withdrawCurrency: function () {
      return this.currency.filter((currency) => {
        if(!this.isFiat)
          return currency.withdraw && currency.crypto;
        return currency.withdraw && !currency.crypto;
      });
    },
    selectedNetwork: {
      get() {
        return this.$store.state.withdrawal.selectedNetwork;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedNetwork', value);
      }
    }
  },
  components: {
    BankList,
    Multiselect,
    Modal
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('withdrawal', [
      'GET_WITHDRAWAL',
      'POST_CREATE_WITHDRAWAL',
      'GET_BALANCE',
      'GET_HISTORY',
      'GET_BANKS'
    ]),
    createWithdrawal() {
      let formData = {
        temp: true,
        amount: this.amount,
        currency: this.selectedCurrency.code.toUpperCase(),
        address : this.walletAddressIban,
        provider: this.selectedCurrency.crypto ? "crypto_currency" : "bank_transfer"
      };
      if(this.selectedCurrency.code.toUpperCase() === 'USDT') {
        formData.network = this.selectedNetwork.network;
      }
      if(this.isFiat) {
        formData.bank = this.selectedProvider ? this.selectedProvider.bank_id : this.selectedCommonBank.id
      } else {
        if(this.selectedProvider) {
          // eslint-disable-next-line no-unused-vars
          const {address, ...restOfFormData} = formData
          formData = {
            ...restOfFormData,
            temp: false,
            wallet_code: this.selectedProvider.code
          }
        }
      }
      this.POST_CREATE_WITHDRAWAL(formData)
        .then(() => {
          this.selectedFiatCrypto = 'crypto';
          this.selectedCommonBank = null;
          this.getCoinSelection(this.withdrawCurrency[0]);
          this.$refs.form.reset();
        })
        .catch(() => {})
    },
    handleContinue() {
      // get fee by user type: getWithdrawalFee(this.getUserType)
      this.$store.commit('withdrawal/SET_TRANSFER', getWithdrawalFee(this.getUserType));
    },
    getCoinSelection(currency) {
      this.amount = null;
      this.selectedProvider = '';
      this.walletAddressIban = '';
      this.selectedCurrency = currency;
      this.GET_HISTORY({ currency: currency.code.toLowerCase(), isNextPage: false });
      this.GET_WITHDRAWAL(currency);
      this.GET_BALANCE(currency.code.toLowerCase());
      this.$refs.form.reset();
    },
    addWalletBtn() {
      if (!this.selectedCurrency.crypto) {
        this.$store.commit('withdrawal/showAddBankAccountUpdate', true);
      } else {
        this.$store.commit('withdrawal/showAddBankAccountUpdate', false);
      }
      this.$store.commit('withdrawal/showAddWalletModalUpdate', true);
    },
    /* addBankWalletBtn() {
      this.$store.commit('withdrawal/showAddWalletModalUpdate', true);
    }, */
    cancelTransfer() {
      this.$store.commit('withdrawal/CANCEL_TRANSFER');
    },
    onNetworkChange() {
      this.amount = null;
      this.selectedProvider = '';
      // this.GET_WITHDRAWAL(this.selectedNetwork.currency);
      this.$refs.form.reset();
    },
    currencySelectCustomLabel ({ name, code }) {
      return `${name} (${code})`
    },
    providerSelectCustomLabel ({ label, address }) {
      return `${label} ${address}`
    },
    onToggleShowBankList() {
      this.showBankList = !this.showBankList;
    },
    onChangeProvider() {
      if(this.selectedProvider?.address) {
        this.walletAddressIban = this.selectedProvider.address;
        this.selectedCommonBank = null;
      }
    },
    onChangeWalletAddressInput() {
      this.selectedProvider = null;
    },
    onChangeFiatCrypto(type) {
      this.selectedFiatCrypto = type;
      if(this.withdrawCurrency.length > 0) {
        this.getCoinSelection(this.withdrawCurrency[0]);
      }
    }
  },
  created() {
    this.GET_CURRENCY().then(() => {
      if(this.withdrawCurrency.length > 0) {
        this.getCoinSelection(this.withdrawCurrency[0]);
      }
    }).catch(() => {});
    this.GET_BANKS();
  }
};
</script>

<style lang="scss" scoped>
.add-wallet {
  cursor: pointer;
  color: #1b65d3;
  @media only screen and (max-width: 768px) {
    padding: 20px 0 20px 5px;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.available-limit {
  text-align: right;
  padding-top: 5px;
}

.provider-input {
  position: absolute;
  left: 0;
  width: 85%;
  z-index: 45;
  // border-right: 0;
  box-shadow: none !important;
  border-color: #cacfe7 !important;
  &.is-invalid {
    border-left: 1px solid #FF4961 !important;
    border-top: 1px solid #FF4961 !important;
    border-bottom: 1px solid #FF4961 !important;
  }
}

.btn-outline-secondary:hover {
  color: #fff !important;
}

.multiselect:hover {
  cursor: pointer !important;
}

.btn-gradient-primary {
  box-shadow: none !important;
}

</style>
