<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form-horizontal form-signin" method="post" @submit.prevent="handleSubmit(registerForm)">
        <div class="row">
          <validation-provider
            rules="required|min:2"
            :name="$t('register-individualName')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group mb-0">
              <input
                type="text"
                class="form-control"
                id="name-personel"
                v-model="register.personel.name"
                :placeholder="$t('register-individualName')"
                :class="classes"
              />
              <label for="name-personel">{{ $t('register-individualName') }}</label>
              <span class="error__message" v-if="errors[0]">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
          <validation-provider
            rules="required|min:2"
            :name="$t('register-individualSurname')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group mb-0">
              <input
                type="text"
                class="form-control"
                id="surname-personel"
                v-model="register.personel.surname"
                :placeholder="$t('register-individualSurname')"
                :class="classes"
              />
              <label for="surname-personel">{{ $t('register-individualSurname') }}</label>
              <span class="error__message" v-if="errors[0]">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
        </div>
        <small class="form-text text-dark mb-2" style="margin-top: -3px;">
          {{ $t('name_surname_register_info') }}
        </small>
        <validation-provider rules="required|email" :name="$t('register-individualEmail')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group">
            <input
              type="email"
              class="form-control"
              id="email-personel"
              v-model="register.personel.email"
              :placeholder="$t('register-individualEmail')"
              :class="classes"
            />
            <label for="email-personel">{{ $t('register-individualEmail') }}</label>
            <span class="error__message">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <validation-provider rules="required" :name="$t('register-country')" v-slot="{ classes, errors }">
          <label>{{ $t('register-country') }}</label>
          <fieldset class="form-label-group">
            <multiselect
              v-model="selectedCountry"
              :placeholder="$t('register-country')"
              label="title"
              track-by="title"
              :options="countries"
              :option-height="104"
              :show-labels="false"
              :class="classes"
              :allow-empty="false"
              open-direction="bottom"
            >
              <template
                slot="singleLabel"
                slot-scope="props"
              >
                <div class="option">
                  <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                  <span class="option__title">{{ props.option.title }}</span>
                </div>
              </template>
              <template
                slot="option"
                slot-scope="props"
              >
                <div class="option">
                  <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                  <span class="option__title">{{ props.option.title }}</span>
                </div>
              </template>
              <template slot="noOptions">{{ $t('noOptions') }}</template>
            </multiselect>
            <span class="error__message">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <validation-provider rules="required" :name="$t('National ID Number')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group">
            <input
              type="text"
              class="form-control"
              id="id_no_personal"
              v-model="register.personel.identity_number"
              :placeholder="$t('National ID Number')"
              :class="classes"
            />
            <label for="id_no_personal">{{ $t('National ID Number') }}</label>
            <span class="error__message">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <validation-provider rules="required" :name="$t('birthday')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group">
            <input
              type="date"
              class="form-control min-width-95p"
              id="birthday-personel"
              v-model="register.personel.birthday"
              :placeholder="$t('birthday')"
              :class="classes"
            />
            <label for="birthday-personel">{{ $t('birthday') }}</label>
            <span class="error__message">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <div class="row">
          <validation-provider
            rules="required|min:8|strongPassword"
            :name="$t('register-individualPassword')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <i
                class="showPassword"
                @click="switchPasswordType()"
                v-bind:class="passwordFieldType === 'password' ? 'passwordEyeOff' : 'passwordEye'"
              ></i>
              <input
                :type="passwordFieldType"
                class="form-control"
                id="user-password-personel"
                v-model="register.personel.password"
                :placeholder="$t('register-individualPassword')"
                :class="classes"
              />
              <label for="user-password-personel">{{ $t('register-individualPassword') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
          <validation-provider
            :rules="'required|min:8|password:@' + $t('register-individualPassword')"
            :name="$t('register-individualPasswordRepeat')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <input
                type="password"
                class="form-control"
                id="user-password-again-personel"
                v-model="register.personel.passwordAgain"
                :placeholder="$t('register-individualPasswordRepeat')"
                :class="classes"
              />
              <label for="user-password-again-personel">{{ $t('register-individualPasswordRepeat') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
        </div>
        <fieldset>
          <div class="input-group">
            <multiselect
              v-model="register.personel.phoneCode"
              :placeholder="$t('login-phone-code')"
              label="title"
              track-by="title"
              :options="countries"
              :option-height="104"
              :show-labels="false"
              :allow-empty="false"
              class="phoneCode"
              open-direction="bottom"
            >
              <template slot="singleLabel" slot-scope="props">
                <div class="option">
                  <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                  <span class="option__title">{{ props.option.code }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option">
                  <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                  <span class="option__title">{{ props.option.code }}</span>
                </div>
              </template>
              <template slot="noOptions">{{ $t('noOptions') }}</template>
            </multiselect>
            <template v-if="register.personel.phoneCode.desc && register.personel.phoneCode.desc === 'nz'">
              <validation-provider
                :name="$t('register-individualPhone')"
                :rules="`required`"
                v-slot="{ classes, errors }"
                class="form-label-group -wd"
              >
                <input
                  class="form-control"
                  :class="classes"
                  id="phone-personel"
                  type="number"
                  v-model="register.personel.phone"
                  :placeholder="$t('register-individualPhone')"
                />
                <label for="phone">{{ $t('register-individualPhone') }}</label>
                <span class="error__message">{{ errors[0] }}</span>
              </validation-provider>
            </template>
            <validation-provider
              v-else
              :name="$t('register-individualPhone')"
              :rules="`required|min: ${minPhoneCount}`"
              v-slot="{ classes, errors }"
              class="form-label-group -wd"
            >
              <the-mask
                class="form-control"
                :class="classes"
                type="tel"
                id="phone-personel"
                v-model="register.personel.phone"
                :placeholder="$t('register-individualPhone')"
                :mask="[...register.personel.phoneCode.phoneMask]"
              />
              <label for="phone-personel">{{ $t('register-individualPhone') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </fieldset>
        <validation-provider
          rules="required"
          :name="$t('explicitConsentName')"
          v-slot="{classes, errors}"
        >
          <fieldset
            class="form-label-group -regular"
            :class="classes"
          >
            <input
              type="checkbox"
              style="margin: 5px"
              id="explicitConsent-personel"
              v-model="explicitConsent"
              :false-value="null"
            />
            <label
              for="explicitConsent-personel"
              class="ml-2"
              @click="onClickExplicitConsent"
              v-html="$t('explicitConsent')"
            ></label>
            <span class="d-block font-small-2" style="color: #dc3545;">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <validation-provider
          rules="required"
          :name="$t('commercialMessagesName')"
          v-slot="{classes, errors}"
        >
          <fieldset
            class="form-label-group -regular"
            :class="classes"
          >
            <input
              type="checkbox"
              style="margin: 5px"
              id="commercialMessages-personel"
              v-model="commercialMessages"
              :false-value="null"
            />
            <label
              for="commercialMessages-personel"
              class="ml-2"
              @click="electronicCommunicationContentModal = true"
              v-html="$t('commercialMessages')"
            ></label>
            <span class="d-block font-small-2" style="color: #dc3545;">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <validation-provider rules="required" :name="$t('register-aggrement')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group -regular" :class="classes">
            <input
              type="checkbox"
              style="margin: 5px"
              id="accept-contract-personel"
              v-model="register.personel.acceptContract"
              :false-value="null"
            />
            <label
              for="accept-contract-personel"
              class="ml-2"
              @click="showPolicyModal = true"
              v-html="$t('register-individualPolicy')"
            ></label>
            <span class="d-block font-small-2" style="color: #dc3545;">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <vue-recaptcha
          size="invisible"
          ref="gotchaPersonal"
          @verify="onGotchaVerify"
          @expired="onGotchaExpired"
          :sitekey="$recaptchaSiteKey"
        />
        <button type="submit" class="btn-gradient-primary text-capitalize btn-block my-1">
          {{ $t('register-btnIndividualRegister') }}
        </button>
        <router-link to="/login" class="btn btn-outline-primary btn-block full-radius">
          {{ $t('register-btnIndividualLogin') }}
        </router-link>
      </form>
    </ValidationObserver>
    <modal v-if="showRegisterModal" ref="modalName">
      <template v-slot:header>
        <h1>{{ $t('modal-title-success') }}</h1>
      </template>
      <template v-slot:body>
        {{ showRegisterModalMessage }}
      </template>
      <template v-slot:footer>
        <router-link @click.native="clearModal" to="/login" class="btn btn-outline-primary full-radius">
          {{ $t('modal-btn') }}
        </router-link>
      </template>
    </modal>
    <modal v-if="showPolicyModal" ref="modalName">
      <template v-slot:header>
        <h1></h1>
      </template>
      <template v-slot:body>
        <div v-html="staticContent.content"></div>
      </template>
    </modal>
    <modal
      v-if="electronicCommunicationContentModal"
      ref="electronicCommunicationModal"
      @onCloseModal="electronicCommunicationContentModal = false"
      :modalHeight="'400px'"
    >
      <template v-slot:header>
        <h1></h1>
      </template>
      <template v-slot:body>
        <div v-html="$t('electronicCommunicationContent')"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/global/Modal';
import Multiselect from 'vue-multiselect';
import { VueRecaptcha } from 'vue-recaptcha';
import i18n from '@/config/_i18n';

export default {
  name: 'Personal',
  components: {
    Multiselect,
    Modal,
    VueRecaptcha
  },
  data() {
    return {
      passwordFieldType: 'password',
      minPhoneCount: 7,
      explicitConsent: null,
      commercialMessages: null,
      electronicCommunicationContentModal: false,
    };
  },
  props: {
    staticContent: Object
  },
  methods: {
    ...mapActions('customer', ['POST_LOGIN', 'POST_LOGIN_OTP', 'GET_COUNTRIES', 'POST_PERSONEL_REGISTER']),
    registerForm() {
      this.$refs.gotchaPersonal.reset();
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$refs.gotchaPersonal.execute();
      });
    },
    clearModal() {
      this.$store.commit('customer/closeModal', false);
    },
    switchPasswordType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    onGotchaVerify(res) {
      this.POST_PERSONEL_REGISTER(res);
      this.$refs.gotchaPersonal.reset();
    },
    onGotchaExpired() {
      this.$refs.gotchaPersonal.reset();
    },
    setMinPhoneCount() {
      const arrayList = this.register.personel.phoneCode.phoneMask;
      let countArray = [];
      arrayList.forEach(function (elem) {
        countArray.push(elem.replace(/[^#]/g, '').length);
      });
      this.minPhoneCount = Math.min(...countArray);
    },
    onClickExplicitConsent() {
      const url = this.isTr ? 'https://miraclecash.com/aydinlatma-metni/' : 'https://miraclecash.com/privacy-notice/';
      window.open(url, '_blank');
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.customer.countries,
      register: (state) => state.customer.register,
      showRegisterModal: (state) => state.customer.showRegisterModal,
      showRegisterModalMessage: (state) => state.customer.showRegisterModalMessage
    }),
    showPolicyModal: {
      get() {
        return this.$store.state.customer.showPolicyModal;
      },
      set(value) {
        this.$store.commit('customer/updatePolicyModal', value);
      }
    },
    isTr() {
      return i18n.locale === 'tr';
    },
    selectedCountry: {
      get() {
        return this.$store.state.customer.register.personel.country;
      },
      set(value) {
        this.$store.commit('customer/onChangePersonalCountry', value);
      }
    },
  },
  watch: {
    'register.personel.phoneCode': {
      handler() {
        this.setMinPhoneCount();
      }
    },
    countries: function(val) {
      if(val && val.length > 0) {
        this.setMinPhoneCount();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.multiselect {
  &.phoneCode {
    width: 110px;
  }

  &__tags {
    height: 54.5px;
    padding: 8px 30px 0 8px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #cacfe7;
    color: #3b4781;
    line-height: 1.5rem;
    border-right: 0;
  }

  &__input,
  &__single {
    min-height: 37px;
    line-height: 37px;
  }

  &__select {
    height: 50px;
  }

  &__placeholder {
    line-height: 34px;
    color: #aeb5da;
  }
}

.option {
  display: flex;
  align-items: center;

  &__image {
    margin-right: 5px;
    height: 13px;
  }

  &__title {
    font-size: 12px;
  }
}

.-wd {
  width: calc(100% - 110px);

  .form-control {
    border-radius: 0 5px 5px 0;
  }
}

.modal__body > div {
  height: calc(100vh - 300px);
}
</style>
