<template>
    <div class="px-2 mt-2">
        <div class="d-flex justify-content-start align-items-center">
            <h6 class="my-md">
                {{ $t('marginTrade.positions') }}
            </h6>
        </div>
        <div class="table-responsive card m-0 p-0">
            <table class="table">
                <thead>
                    <tr>
                        <th>Coin</th>
                        <th>{{ $t('commons.amount') }}</th>
                        <th>{{ $t('marginTrade.total_fee') }}</th>
                        <th>{{ $t('wallet-detail-datatable-operation') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="main-order-row" v-for="(item, index) in positions" :key="`position-data-${index}`">
                        <td>{{ item.currency_code }}</td>
                        <td>{{ item.debit_amount | amountFormatter }}</td>
                        <td>{{ item.total_fee | amountFormatter }}</td>
                        <td>
                            <span class="repay-action-text text-primary" @click="onRepay(item)">
                                {{ $t('marginTrade.Repay') }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <RepayModal v-if="repayModal" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import RepayModal from './RepayModal.vue';
import {cryptoAmountFormatter} from '@/config/_currency';

export default {
    name: 'Positions',
    components: {
        RepayModal
    },
    filters: {
        amountFormatter: cryptoAmountFormatter
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters('margintrade', ['repayModal', 'positions']),
    },
    methods: {
        onRepay(position) {
            this.$store.commit('margintrade/ON_SELECT_POSITION', position);
            this.$store.commit('margintrade/SET_REPAY_MODAL', true);
        }
    }
};
</script>

<style lang="scss" scoped>
.table-responsive {
    height: 390px;
    overflow-y: scroll;
}

.main-order-row td {
    font-weight: bold;
}

.coin-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #c4b7b7;
}

.btn-primary {
    background-color: #642583 !important;
    border-color: #642583 !important;
    &:hover {
        border-color: #735085 !important;
        opacity: 0.8;
    }
}

.repay-action-text {
    font-weight: 800;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

</style>