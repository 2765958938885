<template>
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 text-center mb-5">
        <router-link to="/">
          <img :src="logoDefault" class="card-account-img" alt="card-account-img" />
        </router-link>
      </div>
      <div class="col-10 offset-1 col-md-6 offset-md-3">
        <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right">
          <div class="card-content">
            <div class="card-body p-md-3" v-if="verfiyEmail">
              <div class="d-sm-block d-md-none text-center pb-1">
                <router-link to="/"
                  ><img class="brand-logo" alt="Miracle Cash&More" :src="logoMobile"
                /></router-link>
              </div>
              <p class="text-center h5 mb-3">{{ $t('emailVerifyMessage') }}</p>
              <router-link class="btn btn-primary round btn-block" to="/">{{ $t('modal-btn') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EmailVerify',
  computed: {
    ...mapState({
      verfiyEmail: (state) => state.customer.verifyEmail
    })
  },
  methods: {
    ...mapActions('customer', ['VERIFY_EMAIL']),
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  },
  created() {
    const id = this.getParameterByName('id');
    const verify_code = this.getParameterByName('verify_code');
    this.VERIFY_EMAIL({ id, verify_code });
  },
  beforeCreate() {
    document.querySelector('body').classList.remove('fixed-navbar');
  }
};
</script>

<style lang="scss" scoped>
.card-account-img {
  max-width: 200px;
  width: 100% !important;
  padding-right: 20px;
}
</style>
