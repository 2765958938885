<template>
  <div class="card-body">
    <h4 class="card-title" style="font-size: 20px">
      {{ $t('kyb.title') }}
    </h4>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(handleUpgrade)">
        <div class="col-12 px-0">
          
          <validation-provider rules="required" :name="$t('accountSettings-country')" v-slot="{ errors, classes }" class="row mb-3" v-if="!hasCountry">
            <div class="d-flex flex-column w-100">
              <label for="country" class="col col-form-label col-form-label-lg font-small-3 mb-1">
                {{ $t('accountSettings-country') }}
              </label>
              <div class="col-12">
                <multiselect v-model="selectedCountry" :class="classes" :placeholder="$t('accountSettings-chooseCountry')" track-by="label" :options="countryCodes" :option-height="104" :show-labels="false" :custom-label="countrySelectCustomLabel" @input="onChangeCountry" class="multiselectRegular" :allow-empty="false" open-direction="bottom">
                  <template slot="singleLabel" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ props.option.label }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ props.option.label }}</span>
                    </div>
                  </template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </div>
          </validation-provider>
          <div class="col-12 mb-1 row">
            <span class="font-weight-bold h5">{{ $t('kyb.CorporateDocuments') }}</span>
          </div>
          <validation-provider rules="required|img_required|file_size_less_than_mb:15" :name="$t('kyb.Association')" v-slot="{ classes, errors, validate }">
            <div class="row mb-1">
              <label class="col-sm-8 col-form-label col-form-label-lg font-small-3">
                {{ $t('kyb.Association') }}
              </label>
              <div class="col-sm-4">
                <span class="btn col-12 btn-outline-info round btn-file" :class="classes">
                  {{ $t('browseFile') }}
                  <input id="Association" ref="Association" @change="validate" type="file" class="custom-input" accept="image/*,.pdf" :class="classes" />
                </span>
                <span class="fileName" v-if="$refs.Association && $refs.Association.files[0]">
                  {{ $refs.Association.files[0].name }}
                </span>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </div>
          </validation-provider>
          <validation-provider rules="required|img_required|file_size_less_than_mb:15" :name="$t('kyb.Shareholders')" v-slot="{ classes, errors, validate }">
            <div class="row mb-1">
              <label class="col-sm-8 col-form-label col-form-label-lg font-small-3">
                {{ $t('kyb.Shareholders') }}
              </label>
              <div class="col-sm-4">
                <span class="btn col-12 btn-outline-info round btn-file" :class="classes">
                  {{ $t('browseFile') }}
                  <input id="Shareholders" ref="Shareholders" name="Shareholders" @change="validate" type="file" class="custom-input" accept="image/*,.pdf" :class="classes" />
                </span>

                <span class="fileName" v-if="$refs.Shareholders && $refs.Shareholders.files[0]">
                  {{ $refs.Shareholders.files[0].name }}
                </span>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </div>
          </validation-provider>
          <validation-provider rules="required|img_required|file_size_less_than_mb:15" :name="$t('kyb.Certificate')" v-slot="{ classes, errors, validate }">
            <div class="row mb-1">
              <label class="col-sm-8 col-form-label col-form-label-lg font-small-3">
                {{ $t('kyb.Certificate') }}
              </label>
              <div class="col-sm-4">
                <span class="btn col-12 btn-outline-info round btn-file" :class="classes">
                  {{ $t('browseFile') }}
                  <input id="Certificate" ref="Certificate" name="Certificate" @change="validate" type="file" class="custom-input" accept="image/*,.pdf" :class="classes" />
                </span>
                <span class="fileName" v-if="$refs.Certificate && $refs.Certificate.files[0]">
                  {{ $refs.Certificate.files[0].name }}
                </span>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </div>
          </validation-provider>

          <div class="row mb-1">
            <div class="d-flex align-items-center col-sm-8">
              <label class="col-form-label col-form-label-lg font-small-3">
                {{ $t('kyb.BoardResolution') }}
              </label>
              <div class="checkbox ml-2 align-items-center">
                <input type="checkbox" class="checkbox__input" id="BoardResolutionSwitch" v-model="isBoardResolutionApplicable" :checked="isBoardResolutionApplicable" @change="onChangeBoardResSwitch" />
                <label class="checkbox__label" for="BoardResolutionSwitch"></label>
                <span class="text-secondary font-weight-bold" style="font-size: 0.93rem">{{ $t('commons.if_applicable') }}</span>
              </div>
            </div>
            <validation-provider class="col-sm-4" :rules="isBoardResolutionApplicable ? `required|img_required|file_size_less_than_mb:15` : ''" :name="$t('kyb.BoardResolution')" ref="boardResolutionValidator" v-slot="{ classes, errors, validate }">
              <!-- <label class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                {{ $t('kyb.BoardResolution') }}
              </label> -->
              <transition name="fade">
                <div v-if="isBoardResolutionApplicable">
                  <span class="btn col-12 btn-outline-info round btn-file" :class="classes">
                    {{ $t('browseFile') }}
                    <input id="BoardResolution" ref="BoardResolution" name="BoardResolution" @change="validate" type="file" class="custom-input" accept="image/*,.pdf" :class="classes" />
                  </span>
                  <span class="fileName" v-if="$refs.BoardResolution && $refs.BoardResolution.files[0]">
                    {{ $refs.BoardResolution.files[0].name }}
                  </span>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
                <div v-else>
                  <span class="btn col-12 disabled btn-outline-light text-secondary round btn-file" style="cursor: default; opacity: 0.7">
                    <span>&times;</span>
                    <input id="BoardResolution" type="file" class="custom-input" accept="image/*,.pdf" disabled />
                  </span>
                </div>
              </transition>
            </validation-provider>
          </div>
          <div class="col-12 mb-1 mt-3 row">
            <span class="font-weight-bold h5">{{ $t('kyb.CorporateShareholdersDocuments') }}</span>
          </div>
          <validation-provider rules="required|img_required|file_size_less_than_mb:15" :name="$t('kyb.IdOrPassport')" v-slot="{ classes, errors, validate }">
            <div class="row mb-1">
              <label class="col-sm-8 col-form-label col-form-label-lg font-small-3">
                {{ $t('kyb.IdOrPassport') }}
              </label>
              <div class="col-sm-4">
                <span class="btn col-12 btn-outline-info round btn-file" :class="classes">
                  {{ $t('browseFile') }}
                  <input id="IdOrPassport" ref="IdOrPassport" name="IdOrPassport" @change="validate" type="file" class="custom-input" accept="image/*,.pdf" :class="classes" />
                </span>
                <span class="fileName" v-if="$refs.IdOrPassport && $refs.IdOrPassport.files[0]">
                  {{ $refs.IdOrPassport.files[0].name }}
                </span>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </div>
          </validation-provider>
          <validation-provider rules="required|img_required|file_size_less_than_mb:15" :name="$t('kyb.AddressStatement')" v-slot="{ classes, errors, validate }">
            <div class="row mb-1">
              <div class="col-sm-8">
                <label class="col-form-label pb-0 col-form-label-lg font-small-3">
                  {{ $t('kyb.AddressStatement') }} <span style="font-weight: 600; color: #0f0f0f">({{ $t('kyb.AddressStatementWarning') }})</span>
                </label>
              </div>
              <div class="col-sm-4">
                <span class="btn col-12 btn-outline-info round btn-file" :class="classes">
                  {{ $t('browseFile') }}
                  <input id="AddressStatement" ref="AddressStatement" name="AddressStatement" @change="validate" type="file" class="custom-input" accept="image/*,.pdf" :class="classes" />
                </span>
                <span class="error__message">{{ errors[0] }}</span>
                <span class="fileName" v-if="$refs.AddressStatement && $refs.AddressStatement.files[0]">
                  {{ $refs.AddressStatement.files[0].name }}
                </span>
              </div>
            </div>
          </validation-provider>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <label class="col-form-label col-form-label-lg font-small-3">
              <span class="font-weight-bold">{{ $t('commons.important_info') }}: </span>{{ $t('accepts_only_image_and_pdf_files') }}
            </label>
          </div>
        </div>
        <div class="row text-center mt-2">
          <div class="col-12 col-sm-6 text-center mt-2 order-2 order-sm-1">
            <button type="button" class="btn-gradient-secondary btn-block" @click="cancelUpgrade">
              {{ $t('withdrawal-back') }}
            </button>
          </div>
          <div class="col-12 col-sm-6 text-center mt-2 order-1 order-sm-2">
            <button :type="isLoading ? 'button' : 'submit'" class="btn-gradient-primary btn-block text-uppercase">
              <template v-if="isLoading">
                {{ $t('accountSettings-btnSending') }}
              </template>
              <template v-else>
                {{ $t('accountSettings-btnSave') }}
              </template>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'KybVerification',
  data() {
    return {
      isLoading: false,
      isBoardResolutionApplicable: true,
      selectedCountry: null
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapState({
      user: (state) => state.customer.user,
      documentTypes: (state) => state.account.level_3.documentTypes,
      countryCodes: (state) => state.account.countryCodes,
    }),
    ...mapGetters('customer', ['getFullName']),
    hasCountry() {
      return this.user?.country_code ? true : false;
    }
  },
  methods: {
    ...mapActions('account', ['KYB_VERIFICATION', 'GET_DOCUMENT_TYPE', 'GET_COUNTRY_CODES']),
    async handleUpgrade() {
      let boardResolutionFile = null;
      const associationFile = this.$refs.Association.files[0];
      const shareholdersFile = this.$refs.Shareholders.files[0];
      const certificateFile = this.$refs.Certificate.files[0];
      if (this.$refs.BoardResolution?.files && this.$refs.BoardResolution.files.length > 0) {
        boardResolutionFile = this.$refs.BoardResolution.files[0];
      }
      const idOrPassportFile = this.$refs.IdOrPassport.files[0];
      const addressStatementFile = this.$refs.AddressStatement.files[0];
      const formData = new FormData();
      formData.append('CountryCode', !this.user?.country_code ? this.selectedCountry.countryCode : this.user.country_code.toUpperCase());
      formData.append('FirstGivenName', this.user?.name || '');
      formData.append('FirstSurName', this.user?.surname || '');
      formData.append('DocumentType', this.documentTypes?.length > 0 ? this.documentTypes[0]?.['name'] : 'Passport');
      formData.append('Association', associationFile);
      formData.append('Shareholders', shareholdersFile);
      formData.append('Certificate', certificateFile);
      formData.append('BoardResolution', boardResolutionFile);
      formData.append('IdOrPassport', idOrPassportFile);
      formData.append('AddressStatement', addressStatementFile);
      this.isLoading = true;
      try {
        await this.KYB_VERIFICATION(formData);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    cancelUpgrade() {
      this.$store.commit('account/toggleKybForm', false);
    },
    onChangeBoardResSwitch() {
      if (this.$refs.BoardResolution) {
        this.$refs.BoardResolution.value = null;
      }
      this.$refs.boardResolutionValidator?.reset();
    },
    onChangeCountry() {
      if (this.selectedCountry) {
        this.GET_DOCUMENT_TYPE(this.selectedCountry.countryCode);
      }
    },
    countrySelectCustomLabel({label, countryCode}) {
      return `${label} (${countryCode})`;
    }
  },
  created() {
    this.GET_COUNTRY_CODES();
    if (this.user?.country_code) {
      this.GET_DOCUMENT_TYPE(this.user.country_code.toUpperCase());
    }
  }
};
</script>

<style scoped>
.is-invalid ~ .error__message {
  display: block;
}

[hidden] {
  display: none !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
