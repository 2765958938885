<template>
  <div class="bd-content p-4">
    <h3 class="h3">{{ contentTitle }}</h3>
    <template v-if="contentData.length === 0">
      <content-loader
        :width="1000"
        :height="63"
        :speed="2"
        primaryColor="#f4f5fa"
        secondaryColor="#eee"
        class="loading-bg"
        v-for="(item, index) in 5"
        :key="'loading-' + index"
      >
        <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
      </content-loader>
    </template>
    <p v-for="(item, index) in contentData" :key="'content-' + index" v-html="item"></p>
    <!-- <div class="primeTrustWrapper">
      <div class="primeTrustText">Financial infrastructure APIs and widgets by</div>
      <img v-if="staticContent.content" src="../../assets/images/logo/prime-trust-logo.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import Services from '../../config/_axios';
import Vue from 'vue';
import API from '../../api';

export default {
  name: 'Information',
  components: {
    ContentLoader
  },
  data() {
    return {
      contentTitle: this.$t('commons.important_info'),
      contentData: []
    };
  },
  computed: {
    ...mapState({
      selectedCurrency: (state) => state.deposit.selectedCurrency
    })
  },
  watch: {
    selectedCurrency: function (val) {
      this.getContent(val.code);
    }
  },
  created() {
    this.getContent(this.selectedCurrency?.code || 'TRY');
  },
  methods: {
    getContent(code) {
      Services.get(`${API.depositContent}?currency=${code}`)
        .then((res) => {
          this.contentTitle = res.data?.data?.title || this.$t('commons.important_info');
          this.contentData = res.data?.data?.content || [];
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  }
};
</script>
