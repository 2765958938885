const isDate = (date) => {
  return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export default function(value) {
  if(value && isDate(value)) {
    return new Date(value).toLocaleString('tr-TR');
  }
  return value;
}
