<template>
    <div class="col-12 px-0">
        <ValidationObserver
            ref="form"
            v-slot="{handleSubmit}"
            class="d-flex flex-grow-1"
        >
            <form
                class="flex-grow-1 mt-1"
                @submit.prevent="handleSubmit(onSubmit)"
            >
                <div class="form-group mb-0" v-if="typeIsLimit">
                    <label class="mr-sm-2">{{ $t('commons.price')}}</label>
                    <validation-provider
                        rules="required"
                        :name="$t('commons.price')"
                        v-slot="{classes, errors}"
                        class="col-6"
                    >
                        <currency-input
                            id="order_price"
                            class="form-control"
                            :class="classes"
                            v-model="order.price"
                            :precision="2"
                            :placeholder="$t('commons.price')"
                        />
                        <span class="error__message">{{ errors[0]}}</span>
                    </validation-provider>
                </div>
                <div class="form-group mb-0">
                    <label class="mr-sm-2">{{ getAmountLabel }}</label>
                    <validation-provider
                        rules="required"
                        :name="getAmountLabel"
                        v-slot="{classes, errors}"
                        class="col-6"
                    >
                        <currency-input
                            id="order_amount"
                            class="form-control"
                            :class="classes"
                            v-model="order.amount"
                            :precision="getTotalCurrency.decimal"
                            :placeholder="getAmountLabel"
                        />
                        <span class="error__message">{{ errors[0]}}</span>
                    </validation-provider>
                </div>
                <div class="d-flex justify-content-end mb-2">
                    <span v-if="typeIsLimit">
                        {{ $t('commons.total')}}
                        {{ order.total() | currency(getTotalCurrency.currency, getTotalCurrency.decimal) }}
                    </span>
                    <span v-else>
                        {{ $t('commons.total')}}
                        {{ order.amount ? order.amount : 0 | currency(getTotalCurrency.currency, getTotalCurrency.decimal) }}
                    </span>
                </div>
                <button
                    type="submit"
                    class="btn-block"
                    :class="buttonClass"
                >{{ $t(buttonText) }} {{ selectedPair.baseCurrency }}</button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import currency from '@/config/_currency';
import {OrderDirection, OrderType} from '@/enums/spot-trade-order.enum';

export default {
    name: 'TransactionForm',
    props: ['direction', 'type', 'extraRequestData'],
    filters: { currency },
    data() {
        return {
            order: {
                order_id: null,
                price: null,
                amount: null,
                direction: null,
                symbol: null,
                type: null,
                pair: null,
                status: null,
                canceled_time: null,
                completed_time: null,
                member_id: null,
                time: null,
                traded_amount: null,
                turnover: null,
                use_discount: null,
                base_symbol: null,
                coin_symbol: null,
                total: () => this.order.price * this.order.amount
            }
        };
    },
    computed: {
        ...mapGetters('spottrade', ['selectedPair']),
        isBuy() {
            return this.direction === OrderDirection.Buy;
        },
        buttonText() {
            return this.isBuy ? 'spotTrade.buy' : 'spotTrade.sell';
        },
        buttonClass() {
            return this.isBuy ? 'btn-gradient-success' : 'btn-gradient-danger';
        },
        typeIsLimit() {
            return this.type === OrderType.Limit
        },
        typeIsMarket() {
            return this.type === OrderType.Market
        },
        getAmountLabel() {
            if(this.typeIsLimit) {
                return this.$t('commons.amount');
            }
            return this.$t('commons.total');
        },
        getTotalCurrency() {
            let decimal = 2;
            let currency = this.selectedPair?.quoteCurrency || '';
            if(this.typeIsLimit) {
                decimal = 8;
                currency = this.selectedPair?.quoteCurrency || '';
            }
            if(this.typeIsMarket) {
                decimal = this.isBuy ? 2 : 8;
                currency = this.isBuy ? this.selectedPair?.quoteCurrency || '' : this.selectedPair?.baseCurrency || '';
            }
            return {decimal, currency};
        }
    },
    methods: {
        ...mapActions('spottrade', ['SUBMIT_ORDER']),
        async onSubmit() {
            this.order.direction = this.direction;
            this.order.type = this.type;
            this.order.pair = this.selectedPair.name ?? '1';
            this.order = {...this.order, ...this.extraRequestData};
            const resMsg = await this.SUBMIT_ORDER(this.order);
            if(resMsg === 'success') this.resetForm();
        },
        resetForm() {
            this.order.price = null;
            this.order.amount = null;
            this.$refs.form.reset();
        }
    },
};
</script>