<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 30 30">
        <g id="file">
            <path id="Path_1076" data-name="Path 1076" d="M26.776,5.3,21.678.223A.766.766,0,0,0,21.137,0H9.412A2.292,2.292,0,0,0,7.118,2.285V12.332A6.854,6.854,0,0,0,7.882,26H24.706A2.292,2.292,0,0,0,27,23.715V5.84a.76.76,0,0,0-.224-.539ZM21.9,2.6l2.487,2.477H21.9ZM2.529,19.145a5.353,5.353,0,1,1,5.353,5.332A5.349,5.349,0,0,1,2.529,19.145Zm22.176,5.332H12.2a6.845,6.845,0,0,0,2.519-4.57h7.945a.762.762,0,1,0,0-1.523H14.722a6.8,6.8,0,0,0-1.12-3.047h9.065a.762.762,0,1,0,0-1.523H12.2a6.865,6.865,0,0,0-3.556-1.481V2.285a.764.764,0,0,1,.765-.762H20.373V5.84a.763.763,0,0,0,.765.762h4.333V23.715A.764.764,0,0,1,24.706,24.477Z" transform="translate(0 0)" :fill="color" :stroke="color" stroke-width="0.5"/>
            <path id="Path_1077" data-name="Path 1077" d="M202.992,182H191.765a.765.765,0,0,0,0,1.531h11.227a.765.765,0,0,0,0-1.531Z" transform="translate(-180.33 -172.761)" :fill="color" :stroke="color" stroke-width="0.5"/>
            <path id="Path_1078" data-name="Path 1078" d="M123.807,306.593a.765.765,0,1,0,0-1.531h-1.276v-2.3a.765.765,0,1,0-1.531,0v3.062a.765.765,0,0,0,.765.766Z" transform="translate(-113.883 -286.68)" :fill="color" :stroke="color" stroke-width="0.5"/>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'DollarCogSVG',
    props: {
        color: {
            type: String,
            default: '#ffffff'
        },
        width: {
            type: String,
            default: '31'
        },
        height: {
            type: String,
            default: '31'
        }
    }
}
</script>