<template>
  <div class="row">
    <div id="recent-transactions" class="col-12 mt-2">
      <h3 class="my-2">{{ selectedCurrencyCode }} {{ $t('deposit-depositHistory') }}</h3>
      <div class="card">
        <div class="card-content">
          <div class="table-responsive">
            <table id="recent-orders" class="table table-hover table-xl mb-0">
              <thead>
                <tr>
                  <th class="border-top-0">{{ $t('deposit-datatable-date') }}</th>
                  <th class="border-top-0">{{ $t('deposit-datatable-operation') }}</th>
                  <th class="border-top-0">{{ $t('deposit-datatable-currency') }}</th>
                  <th class="border-top-0">{{ $t('deposit-datatable-total') }}</th>
                  <th class="border-top-0">TxID</th>
                  <th class="border-top-0">{{ $t('deposit-datatable-status') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="isLoading">
                  <tr>
                    <td colspan="6" class="text-center loading-content">
                      <content-loader :width="1000" :height="63" :speed="2" primaryColor="#ffffff" secondaryColor="#eee" class="loading-bg" v-for="(item, index) in 3" :key="'loading-' + index">
                        <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
                      </content-loader>
                    </td>
                  </tr>
                </template>
                <template v-else-if="history.length === 0">
                  <tr>
                    <td colspan="6" class="text-center">{{ $t('deposit-depositHistoryNoData') }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in history" :key="'history-' + index" :class="['progress-status', progressStatusColors['transfer'][item.status]]">
                    <td class="text-truncate">{{ item.created_at | dateFormat }}</td>
                    <td class="text-truncate">
                      <span class="progress-type deposit">
                        {{ $t('deposit-datatable-deposit') }}
                      </span>
                    </td>
                    <td><i class="cc" v-bind:class="selectedCurrency.code.toUpperCase() + '-alt'"></i> {{ item.currency }}</td>
                    <td class="text-truncate p-1">{{ item.amount_formatted }}</td>
                    <td class="text-truncate">
                      <div class="d-flex flex-row align-items-center">
                        <p class="txid-truncate cursor-pointer pt-1" @click="copyText(item.txhash)">{{ item.txhash }}</p>
                        <i class="fa fa-copy cursor-pointer ml-1 copy-icon" v-if="item.txhash" @click="copyText(item.txhash)"></i>
                      </div>
                    </td>
                    <td class="text-truncate">
                      <div class="row d-flex align-items-center justify-content-evenly">
                        <ProgressBar :key="`progress-${item.code}-${index}`" shape="line" :progressValue="getProgressValue(item.status)" :background="statusColor(item.status)" />
                        <span class="ml-1">
                          {{ item.status_str }}
                        </span>
                      </div>
                    </td>
                    <td v-if="item.status == 70">
                      <div class="justify-content-center">
                        <button type="button" active-class="active" class="btn btn-outline-success round col-12 mb-1" @click="approveTransaction(item.code)">
                          {{ $t('commons.approve') }}
                        </button>
                        <button type="button" active-class="active" class="btn btn-outline-danger round col-12" @click="cancelTransaction(item.code)">
                          {{ $t('commons.cancel') }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 text-center mt-2 mb-2" v-if="next != null">
          <button type="button" class="btn-gradient-primary" @click="getMore">{{ $t('deposit-loadMore') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/config/_date-format';
import { mapState, mapActions } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import ProgressBar from '@/components/global/ProgressBar.vue';
import utils from '@/config/utils';
import {SockIOService} from '../../config/ofinansWebSocket';

export default {
  name: 'DepositHistory',
  components: {
    ContentLoader,
    ProgressBar
  },
  filters: {
    dateFormat
  },
  methods: {
    getMore() {
      if (this.next) this.GET_HISTORY({ isNextPage: true });
    },
    cancelTransaction(code) {
      this.CANCEL_OPERATION(code);
    },
    approveTransaction(code) {
      this.CONFIRM_OPERATION(code);
    },
    ...mapActions('deposit', ['GET_HISTORY', 'CONFIRM_OPERATION', 'CANCEL_OPERATION']),
    getProgressValue: utils.getProgressValue,
    statusColor: utils.getProgressColor,
    async copyText(text) {
      if(!text) return;
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.info(this.$t('toast-copyText'));
      } catch (error) {
        console.log('copyText error: ', error);
        this.$toast.error(this.$t('service.fatal_error'));
      }
    }
  },
  computed: {
    ...mapState({
      history: (state) => state.deposit.history,
      selectedCurrency: (state) => state.deposit.selectedCurrency,
      next: (state) => state.deposit.next,
      isLoading: (state) => state.deposit.isLoading,
      progressStatusColors: (state) => state.global.progressStatusColors
    }),
    selectedCurrencyCode() {
      return this.selectedCurrency?.code ? this.selectedCurrency.code : '';
    }
  },
  created() {
    this.GET_HISTORY({ isNextPage: false });
    SockIOService.init();
    SockIOService.socket.on('deposit', (/* data */) => {
      /* console.log('deposit: ', data); */
      this.$store.commit('deposit/IS_LOADING', true);
      this.GET_HISTORY({ isNextPage: false });
    });
  },
  destroyed() {
    SockIOService.disconnect();
    
  }
  
};
</script>

<style scoped>
.btn-status {
  cursor: default !important;
}
.btn {
  padding: 0.75rem 2rem;
}
.table td {
  vertical-align: middle;
}
.txid-truncate {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txid-truncate:hover {
  color: rgb(100, 37, 131);
}
.copy-icon:hover {
  color: rgb(100, 37, 131);
}
</style>
