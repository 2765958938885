<template>
    <div class='content-wrapper'>
      <Breadcrumb :title="'Google Authenticator'" />
      <div class='content-body'>
        <div class='row'>
          <div class='col-xl-8 col-lg-8 col-md-12'>
            <div class='card'>
              <div class='card-body'>
                <GoogleAuth />
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Sidebar from '@/components/personal/Sidebar.vue';
  import GoogleAuth from '@/components/personal/GoogleAuth.vue';
  import Breadcrumb from '@/components/layout/Breadcrumb';
  
  export default {
    name: 'TwoFAGoogleAuth',
    components: {
      Sidebar,
      GoogleAuth,
      Breadcrumb
    }
  };
  </script>
  
  <style scoped></style>
  