<template>
  <div class="col-12 text-center">
    <a
      class="changeLanguage"
      v-for="language in languages"
      :key="language.lang_code"
      @click="changeLanguage(language.lang_code)"
    >
      <i :class="`flag-icon flag-icon-${language.flag_code}`"></i>
      {{language.title}} 
    </a>
  </div>
</template>

<script>
import { changeLanguages } from '@/config/_i18n';
import {languages} from '@/config/langList';

export default {
  name: 'Language',
  data: () => ({
    languages
  }),
  methods: {
    changeLanguage(lang) {
      changeLanguages(lang, true);
    }
  }
};
</script>

<style scoped lang="scss">
.changeLanguage {
  color: #6b6f82 !important;
  margin: 5px 10px;
  display: inline-block;
}
</style>
