<template>
  <div :class="progressBarClass" :id="randomId"></div>
</template>

<script>
const ProgressBar = require('progressbar.js');

export default {
  name: 'progressbar',
  props: ['background', 'shape', 'progressValue'],
  datra() {
    return {
      progressBar: null
    };
  },
  computed: {
    randomId() {
      return (
        'progress_' +
        Math.random()
          .toString(36)
          .substring(2, 9)
      );
    },
    progressBarClass() {
      return 'progessbar ' + this.shape;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startProgressBar();
    });
  },
  watch: {
    progressValue() {
      this.startProgressBar();
    }
  },
  methods: {
    startProgressBar() {
      let shape = null;

      switch (this.shape) {
        case 'line':
          shape = ProgressBar.Line;
          break;
        case 'circle':
          shape = ProgressBar.Circle;
          break;
        case 'semicircle':
          shape = ProgressBar.SemiCircle;
          break;
      }

      const options = {
        color: this.background,
        strokeWidth: 6,
        trailWidth: 6,
        trailColor: '#E5E7EA',
        easing: 'easeInOut'
      };

      this.progressBar = new shape(`#${this.randomId}`, options);
      this.progressBar.animate(this.progressValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.progessbar {
  position: relative;
  display: flex;
  align-items: center;
  .circle,
  .semicircle {
    width: 30%;
    height: 20px;
  }
}
</style>