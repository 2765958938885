<template>
  <div class="content-wrapper">
    <Breadcrumb :title="$t('personalMenu-accountSettings')" />
    <div class="content-body customer">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-12">
          <div v-if="isLoading">{{$t('commons.data_loading')}}..</div>
          <section class="card" style="height: 100%;" v-else>
            <div class="card-content" v-if="showUpgrade.mainMenu">
              <div class="card-body">
                <div class="col-12">
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-envelope"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-eMail') }}</h2>
                      <p>
                        <strong>{{ user.email }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-user"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ getUserFullNameLabel }}</h2>
                      <p>
                        <strong>{{ getFullName }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-call-end"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-phone') }}</h2>
                      <p>
                        <strong>{{ user.phone }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-globe"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-language') }}</h2>
                      <p>
                        <strong>{{ getSelectedLangName }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="levelStatus">
                <div class="col-12">
                  <h5>{{ user.level_str }}</h5>
                  <hr />
                  <div class="alert alert-primary" role="alert">
                    <i class="la la-info"></i>
                    {{ $t('accountSettings-levelUpReqUnderReview') }}
                  </div>
                </div>
              </div>
              <div class="card-body" v-else>
                <div class="col-12">
                  <h5>{{ user.level_str }}</h5>
                  <hr />
                  <!-- <div class="row" v-if="userlevels['level' + userLevel / 10]">
                    <div class="col-md-9 col-xl-9 col-12">
                      <ul style="list-style: none" class="pl-2">
                        <li v-for="(item, index) in userlevels['level' + userLevel / 10].content" :key="index">
                          <i class="la la-asterisk"></i>{{ item }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-3 col-xl-3 col-12 d-md-block text-right">
                      <a
                        :href="getMoreDetailsLink"
                        target="_blank"
                        class="btn btn-outline-info round btn-block text-truncate"
                        :title="$t('accountSettings-cardMoreInfo')"
                      >
                        {{ $t('accountSettings-cardMoreInfo') }}
                      </a>
                    </div>
                  </div> -->
                </div>
                <div class="alert alert-primary" role="alert" v-if="isAccountUpgraded" v-html="$t('level-complete')"></div>
                <template v-else>
                  <template v-if="getUserVerificationMethod === 'kyc-step-1-2'">
                    <a
                      href="#"
                      v-if="userLevel / 10 == '1'"
                      @click="$store.commit('account/showSecondLevel')"
                      class="btn-gradient-primary btn-block text-center my-1"
                    >
                      {{ $t('accountSettings-upgradeLvl1') }}
                    </a>
                    <a
                      href="#"
                      v-else-if="userLevel / 10 == '2'"
                      @click="$store.commit('account/showThirdLevel')"
                      class="btn-gradient-primary btn-block text-center my-1"
                    >
                      {{ $t('accountSettings-upgradeLvl2') }}
                    </a>
                  </template>
                  <template v-else-if="getUserVerificationMethod === 'onfido'">
                    <button
                      type="button"
                      @click="$store.commit('account/toggleOnfidoScreen', true)"
                      class="btn-gradient-primary btn-block text-center my-1 text-capitalize"
                    >
                      {{ $t('upgradeYourAccount') }}
                    </button>
                  </template>
                  <template v-else-if="getUserVerificationMethod === 'kyb'">
                    <button
                      type="button"
                      @click="$store.commit('account/toggleKybForm', true)"
                      class="btn-gradient-primary btn-block text-center my-1 text-capitalize"
                    >
                      {{ $t('upgradeYourAccount') }}
                    </button>
                  </template>
                </template>
              </div>
            </div>
            <div class="card-content" v-if="getUserVerificationMethod === 'kyc-step-1-2'">
              <SecondLevelUpgrade v-if="showUpgrade.secondLevel" />
              <ThirdLevelUpgrade v-if="showUpgrade.thirdLevel" />
            </div>
            <div class="card-content" style="min-height: 550px;" v-if="showUpgrade.onfidoScreen">
              <OnfidoKYC />
            </div>
            <div class="card-content" v-if="getUserVerificationMethod === 'kyb'">
              <KybVerification v-if="showUpgrade.kybVerification" />
            </div>
          </section>
        </div>
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import '@/assets/css/pages/buy-ico.css';
import Sidebar from '@/components/personal/Sidebar.vue';
import Breadcrumb from '@/components/layout/Breadcrumb';
import SecondLevelUpgrade from '@/components/personal/SecondLevelUpgrade';
import ThirdLevelUpgrade from '@/components/personal/ThirdLevelUpgrade';
import KybVerification from '@/components/personal/KybVerification';

import { languages } from '@/config/langList';
import OnfidoKYC from '@/components/personal/OnfidoKYC';

export default {
  name: 'AccountSettings',
  components: {
    Sidebar,
    Breadcrumb,
    SecondLevelUpgrade,
    ThirdLevelUpgrade,
    OnfidoKYC,
    KybVerification
  },
  data: () => ({
    languages
  }),
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      user: (state) => state.customer.user,
      isLoading: (state) => state.customer.isLoading,
      userlevels: (state) => state.customer.userlevels,
      showUpgrade: (state) => state.account.showUpgrade
    }),
    ...mapGetters('customer', ['userLevel', 'isAccountUpgraded', 'getFullName', 'getUserVerificationMethod']),
    levelStatus() {
      return this.user?.level_status ?? false;
    },
    getSelectedLangName() {
      return this.languages[this.lang]['title'] || 'English';
    },
    getMoreDetailsLink() {
      let link = 'https://miraclecash.com/';
      if(this.lang === 'tr') {
        return `${link}tr`;
      }
      if(this.lang === 'de') {
        return `${link}de`;
      }
      return link;
    },
    getUserFullNameLabel() {
      const fullNameLabel = `${this.$t('accountSettings-name')} ${this.$t('accountSettings-surname')}`;
      const companyLabel = `${this.$t('register-corporateCompanyName')}`;
      if(this.user?.type && `${this.user.type}` === '10') return fullNameLabel;
      return this.user?.company ? companyLabel : fullNameLabel;
    }
  },
  methods: {
    ...mapActions('customer', ['GET_LEVELS']),
    ...mapActions('account', ['CHANGE_LANG'])
  },
  created() {
    this.GET_LEVELS();
  }
};
</script>
<style lang="scss">
.customer {
  h2 {
    font-size: 1rem;
  }

  .crypto-circle {
    width: 46px;
    height: 46px;
  }

  .crypto-circle i {
    font-size: 1.5rem !important;
    top: 13px;
    left: 13px;
  }

  b,
  strong {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 601px) {
  .expand {
    margin: 1.5rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .expand {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
</style>
