<template>
    <div class="card-body">
        <h5 class="card-title text-capitalize" v-if="isLoading">
            {{ $t('commons.data_loading') }}.......
        </h5>
        <h5 class="card-title text-capitalize" v-else>
            <button type="button" class="btn btn-icon bg-transparent pl-0" @click="onPressCancel">
                <i class="la la-arrow-left text-dark" style="font-size: 20px; font-weight: bold;"></i>
            </button>
            {{ $t('upgradeYourAccount') }}
        </h5>
        <div class="col-12 px-0">
            <div id="onfido-init"></div>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapActions } from 'vuex';
import { init as OnfidoInit } from 'onfido-sdk-ui';
import i18n from '@/config/_i18n';

export default {
    name: 'OnfidoKYC',
    data() {
        return {
            isLoading: false,
            onfidoInstance: null

        };
    },
    components: {
    },
    computed: {
        ...mapState({
            user: (state) => state.customer.user
        }),
        onfidoLanguage() {
            let langs = {
                en: 'en_US',
                tr: 'tr',
                me: 'hr',
                et: 'et',
                de: 'de',
                fr: 'fr'
            };
            return langs[i18n.locale] || 'en_US';
        },
    },
    methods: {
        ...mapActions('account', ['getOnfidoKycWorkflow']),
        ...mapActions('customer', ['GET_USER']),
        onPressCancel() {
            this.$store.commit('account/toggleOnfidoScreen', false);
        },
        async initKyc() {
            this.isLoading = true;
            this.onfidoInstance = null;
            try {
                const { workflowRunId, sdkToken } = await this.getOnfidoKycWorkflow();
                this.isLoading = false;
                const _onfidoInstance = OnfidoInit({
                    token: sdkToken,
                    containerId: 'onfido-init',
                    language: this.onfidoLanguage,
                    onComplete: async () => {
                        this.GET_USER();
                        this.$toast.success(this.$t('service.operation_success'));
                        this.onPressCancel();
                    },
                    onError: async (/* err */) => {
                        // console.log(err)
                        this.$toast.error(this.$t('service.fatal_error'));

                    },
                    onUserExit: async () => {
                        // console.log('on user exit')
                        this.onPressCancel();
                    },
                    workflowRunId,
                    customUI: {
                        "colorBackgroundButtonPrimary": "#642583",
                        "colorBackgroundButtonPrimaryHover": "#642583"
                    },
                });
                this.onfidoInstance = _onfidoInstance;
            } catch (error) {
                this.isLoading = false;
            }
        }
    },
    created() {
        this.initKyc();
    },
    destroyed() {
        if (this.onfidoInstance) {
            this.onfidoInstance.safeTearDown();
        }
    }
};
</script>
  
<style lang="scss" scoped>
.is-invalid~.error__message {
    display: block;
}

[hidden] {
    display: none !important;
}
#onfido-init {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

@media (max-width: 479px) {
    #onfido-init {
        width: 100%;
        max-width: 100%;
        height: 600px !important;
    }
}
</style>

<style lang="scss">
@media only screen and (min-width: 480px) and (max-width: 590px) {
    .onfido-sdk-ui-Modal-inner {
        width: 80vw !important;
        max-width: 32em !important;
    }
}
</style>
  