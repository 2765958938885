<template>
  <div class="text-center">
    <p v-if="isLoading">{{ $t('commons.data_loading') }}</p>
    <template v-else>
      <template v-if="!isGoogleAuthActive">
        <img :src="googleAuthQr" class="img-thumbnail google-auth-qr mb-2 mx-auto" />
        <div class="alert alert-warning mb-2 w-50 mx-auto" role="alert">
          <i class="la la-warning" style="font-size: 2rem"></i>
          <p v-html="$t('google_auth_qr_desc')" class="text-bold-600"></p>
        </div>
        <div class="form-group d-flex flex-column align-items-center">
          <label for="codeInput">{{ $t('login-phone-code') }}</label>
          <input type="text" class="form-control code-input w-50" aria-describedby="codeInputHelp" v-model="code" id="codeInput" />
          <small id="codeInputHelp" class="form-text text-muted w-50" v-html="$t('google_auth_verify_input_help')"></small>
        </div>
        <button type="button" :disabled="!code | isSubmitting" @click="handleEnable" class="btn-gradient-primary w-50 text-capitalize">
          {{ $t('commons.submit') }}
        </button>
      </template>
      <template v-else>
        <div class="alert alert-success mb-2 mx-auto w-50" role="alert">
          <i class="ft-check-circle" style="font-size: 2rem"></i>
          <p v-html="$t('google_auth_is_enabled')" class="text-bold-600"></p>
        </div>
        <div class="form-group d-flex flex-column align-items-center">
          <label for="password">{{ $t('login-password') }}</label>
          <input type="password" class="form-control w-50" v-model="password" id="password" />
        </div>
        <button type="button" :disabled="!password | isSubmitting" @click="promptDisable" class="btn-gradient-danger w-50 text-capitalize">
          {{ $t('commons.disable_it') }}
        </button>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'GoogleAuth',
  data: () => ({
    code: '',
    isSubmitting: false,
    password: ''
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.customer.isLoading,
      isGoogleAuthActive: (state) => state.customer.user?.auth_2fa ? `${state.customer.user.auth_2fa}` === '30' : false,
      googleAuthQr: (state) => state.customer.googleAuthQr,
    })
  },
  methods: {
    ...mapActions('customer', ['GET_USER', 'FETCH_GOOGLE_AUTHENTICATOR', 'ENABLE_GOOGLE_AUTHENTICATOR', 'DISABLE_GOOGLE_AUTHENTICATOR']),
    async handleEnable() {
      if (!this.code) {
        return;
      }
      this.isSubmitting = true;
      await this.ENABLE_GOOGLE_AUTHENTICATOR(this.code.replace(/\s/g, ""));
      await this.GET_USER();
      this.isSubmitting = false;
    },
    promptDisable() {
      if (!this.password) {
        return;
      }
      Swal.fire({
        title: this.$t('modal-title-warning'),
        text: this.$t('you_will_be_logged_out_after'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes'),
        cancelButtonText: this.$t('commons.cancel')
      }).then((result) => {
        if (result.value) {
          this.handleDisable();
        }
      });
    },
    async handleDisable() {
      this.isSubmitting = true;
      await this.DISABLE_GOOGLE_AUTHENTICATOR(this.password);
      this.isSubmitting = false;
    }
  },
  created() {
    if (!this.isGoogleAuthActive) {
      this.FETCH_GOOGLE_AUTHENTICATOR();
    }
  }
};
</script>
<style lang="scss" scoped>
.code-input {
  font-size: 20px;
  letter-spacing: 10px;
}
.google-auth-qr {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  box-shadow: 0 0 40px 32px rgba(0, 0, 0, 0.09);
}
</style>
