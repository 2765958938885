<template>
  <div>
    <div class="row">
      <label for="currency" class="col-sm-4 col-form-label col-form-label-lg px-3">{{
        $t('personal-history-label')
      }}</label>
      <div class="col-sm-8">
        <multiselect
          v-model="selectedCurrency"
          @input="getCoinSelection"
          deselect-label=""
          select-label=""
          selected-label=""
          id="currency"
          track-by="name"
          :label="$t('commons.currency')"
          :custom-label="currencySelectCustomLabel"
          :show-labels="false"
          :placeholder="$t('commons.currency')"
          :options="activeCurrency"
          :allow-empty="false"
          open-direction="bottom"
        >
          <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
          <template slot="noResult">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-xl">
        <thead>
          <tr>
            <th class="border-top-0">{{ $t('history-transaction') }}</th>
            <th class="border-top-0">{{ $t('history-dateHour') }}</th>
            <th class="border-top-0">{{ $t('history-price') }}</th>
            <th class="border-top-0">{{ $t('history-amount') }}</th>
            <th class="border-top-0">{{ $t('history-activeAmount') }}</th>
            <th class="border-top-0">{{ $t('history-status') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoading">
            <tr>
              <td colspan="6" class="text-center loading-content">
                <content-loader
                  :width="1000"
                  :height="63"
                  :speed="2"
                  primaryColor="#ffffff"
                  secondaryColor="#eee"
                  class="loading-bg"
                  v-for="(item, index) in 3"
                  :key="'loading-' + index"
                >
                  <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
                </content-loader>
              </td>
            </tr>
          </template>
          <template v-else-if="history.length === 0">
            <tr>
              <td colspan="6" class="text-center">{{ $t('history-noData') }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(item, index) in history"
              :key="'history-' + index"
              :class="['progress-status', progressStatusColors['transfer'][item.status]]"
            >
              <td class="text-truncate">
                <span class="progress-type transfer">{{ item.type }}</span>
              </td>
              <td class="text-truncate">{{ item.created_at | dateFormat }}</td>
              <td class="text-truncate">{{ item.price_formatted }}</td>
              <td class="text-truncate">{{ item.amount_formatted }}</td>
              <td class="text-truncate">{{ item.total_formatted }}</td>
              <td class="text-truncate">
                <span class="progress-text d-flex justify-content-start align-items-center">
                  <i class="la la-dot-circle-o font-medium-1 m-r-5"></i>
                  {{ item.status_str }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="col-12 text-center mt-2 mb-2" v-if="next != null">
        <button type="button" class="btn-gradient-primary" @click="getMore">{{ $t('history-loadMore') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import dateFormat from '@/config/_date-format';
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'AccountHistory',
  components: {
    Multiselect,
    ContentLoader
  },
  data() {
    return {
      selectedCurrency: {
        code: 'TRY',
        name: this.$t('wallet-turkish-lira')
      }
    };
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      history: (state) => state.global.history,
      next: (state) => state.global.next,
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      progressStatusColors: (state) => state.global.progressStatusColors
    }),
    activeCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.balance;
      });
    }
  },
  methods: {
    ...mapActions('global', ['GET_HISTORY', 'GET_CURRENCY']),
    getCoinSelection() {
      this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
    },
    getMore() {
      if (this.next) this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: true });
    },
    currencySelectCustomLabel({ name, code }) {
      return `${name} (${code})`
    },
  },
  created() {
    this.GET_CURRENCY();
    this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
  }
};
</script>

<style scoped></style>
